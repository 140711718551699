import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const state = () => {
    return {
        login: {},
        productid: null,
        mainType: null,
    };
};

const mutations = {
    login(state, value) {
        state.login = value;
    },
    productid(state, value) {
        state.productid = value;
    },
    mainType(state, value) {
        state.mainType = value;
    },
};

const getters = {
    login (state) {
        // state variable contains our state data
        return state.login;
    },
    productid () {
        return state.productid;
    },
    mainType () {
        return state.mainType;
    }
}

const actions = {}

/* eslint-disable no-new */
export default () => {
    return new Vuex.Store({
        strict: true,
        plugins: [
            createPersistedState({
                paths: ["login", "productid"],
                getState: (key) => (key in localStorage ? JSON.parse(localStorage[key]) : null),
                setState: (key, state) => (localStorage[key] = JSON.stringify(state)),
            }),
        ],
        state,
        getters,
        mutations,
        actions,
    });
};
