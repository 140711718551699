<template>
    <div>
        <div class="modal" :class="{ active: showUpload, loading: uploading }" id="modal-upload">
            <a href="#close" class="modal-overlay" aria-label="Close" @click.prevent=""></a>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-title h5 float-left">Datei Upload</div>
                    <a href="#close" class="btn btn-clear float-right" aria-label="Close" @click.prevent="showUpload = false"></a>
                </div>
                <div class="modal-body">
                    <div class="content">
                        <form v-show="!uploading">
                            <div class="columns">
                                <div class="column col-4">
                                    <div class="form-group">
                                        <select name="meta_mediatype" v-model="form.meta_mediatype" class="form-select">
                                            <option v-for="option in mediatypes" :key="option" :value="option">{{ option }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="column col-8">
                                    <div class="form-group">
                                        <label class="form-checkbox">
                                            <input type="checkbox" v-model="form.is_main" />
                                            <i class="form-icon"></i> Ist das Hauptbild der Kategorie "{{ form.meta_mediatype }}"
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="file" name="file" ref="imageUploader" class="form-control" v-on:change="handleFileUpload($event)" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" :class="{ active: currentRow !== false }" id="modal-edit">
            <a href="#close" class="modal-overlay" aria-label="Close" @click.prevent=""></a>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-title h5 float-left">Bearbeiten</div>
                    <a href="#close" class="btn btn-clear float-right" aria-label="Close" @click.prevent="currentRow = false"></a>
                </div>
                <div class="modal-body">
                    <div class="content">
                        <form>
                            <div class="columns">
                                <div class="column col-4">
                                    <div class="form-group">
                                        <select name="meta_mediatype" v-model="currentRow.meta_mediatype" class="form-select">
                                            <option v-for="option in mediatypes" :key="option" :value="option">{{ option }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="column col-8">
                                    <div class="form-group">
                                        <a v-show="!currentRow.is_main" href="#" @click.prevent="currentRow.is_main = true">Als Hauptbild der Kategorie "{{ currentRow.meta_mediatype }}" setzen</a>
                                        <p v-show="currentRow.is_main">Ist Hauptbild der Kategorie "{{ currentRow.meta_mediatype }}"</p>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column col-6">
                                    <div class="form-group">
                                        <button class="btn btn-success" @click.prevent="file_save(currentRow)">Speichern</button>
                                    </div>
                                </div>
                                <div class="column col-6">
                                    <div class="form-group text-right">
                                        <button class="btn btn-error" @click.prevent="file_delete(currentRow)">Löschen</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div v-show="noProductIdSet">
                <h1 style="font-size: 20px">Trend-Stoff Dateiserver</h1>
                <p>Keine Artikel-Nr angegeben</p>
            </div>
            <div class="images" v-show="!noProductIdSet">
                <div class="imgContainer loading" v-show="loading"></div>
                <div class="imgContainer" v-show="images.length == 0 && !loading">Keine Bilder für <span v-show="mainType=='product'">das Produkt</span><span v-show="mainType=='collection'">die Kollektion</span> <i>{{entityid}}</i> gefunden</div>
                <div class="imgContainer" v-for="row in images" :key="row.uid" :class="{ isImage: row.isImage, is_main: row.sorting == 0 }">
                    <inner-image-zoom :src="row.imageUrl + '?width=200&height=200'" :zoomSrc="row.imageUrl + '?width=400&height=400'" class="image" zoomType="hover" :hideHint="true" v-if="row.isImage" />
                    <img :src="row.imageUrl + '?width=200&height=200'" v-if="!row.isImage" />
                    <div class="dropdown">
                        <button class="btn btn-primary btn-sm btn-action dropdown-toggle" tabindex="0"><i class="icon icon-menu"></i></button>
                        <!-- menu component -->
                        <ul class="menu">
                            <li class="menu-item"><a href="#" @click.prevent="file_dl(row)">Herunterladen</a></li>
                            <li class="menu-item"><a href="#" @click.prevent="file_edit(row)">Bearbeiten</a></li>
                        </ul>
                    </div>
                    <div class="footer">{{ row.meta_mediatype }}</div>
                </div>
                <div class="imgContainer addNew" @click.prevent="showUpload = true"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import store from "../store/vuex-store";
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';

export default {
    components: {
        'inner-image-zoom': InnerImageZoom
    },
    data: () => ({
        entityid: 0,
        images: [],
        loading: false,
        uploading: false,
        showUpload: false,
        form: {
            meta_mediatype: "Standard",
        },
        currentRow: false,
        noProductIdSet: false,
        mainType: "product",
        mediatypes: ["Standard", "Close Up", "Ambiente", "Faltenwurf", "Kollektion", "Datenblatt", "Sonstiges"],
    }),
    methods: {
        load() {

            if (this.entityid && this.entityid != "") {
                this.noProductIdSet = false;
                window.entityid = this.entityid;
            } else if (window.entityid) {
                this.$router.push("/images/" + window.entityid);
            } else {
                this.noProductIdSet = true;
                return;
            }

            this.loading = true;
            this.$http
                .get(Vue.config.api_url + "/list/?meta_entityid=" + this.entityid + '&meta_maintype=' + this.mainType + '&nc=' + new Date().getTime())
                .then((r) => {
                    console.log(r);
                    this.loading = false;
                    var result = [];
                    for (var i = 0; i < r.body.data.data.length; i++) {
                        var row = r.body.data.data[i];
                        row.imageUrl = Vue.config.api_url + "/file/" + row.uid + "/" + encodeURIComponent(row.filename);
                        row.isImage = row.mimetype.includes("image/");
                        row.is_main = row.sorting == 0;
                        result.push(row);
                    }
                    console.log(result);
                    this.images = result;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        handleFileUpload(event) {
            let formData = new FormData();
            formData.append("file", event.target.files[0]);
            formData.append("meta_entityid", this.entityid);
            formData.append("meta_mediatype", this.form.meta_mediatype);
            formData.append("meta_maintype", this.mainType);
            formData.append("is_main", this.form.is_main);

            let headers = {
                Authorization: "Bearer " + store(Vuex).getters.login.token,
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            };

            this.uploading = true;

            this.$http.post(Vue.config.api_url + "/file", formData, { headers }).then(
                (response) => {
                    this.$refs.imageUploader.value = '';
                    this.uploading = false;
                    this.showUpload = false;
                    this.load();
                    console.log(response);
                },
                (response) => {
                    this.$refs.imageUploader.value = '';
                    this.uploading = false;
                    if (response.status == 401) {
                        this.$router.push("/login");
                    } else if (response.status == 413) {
                        alert("Datei zu groß");
                    } else {
                        console.error(response);
                        //Harald von TS meldet, dass diese Meldung kommt, obwohl das Bild erfolgreich hochgeladen und sofort angezeigt wird.
                        //alert(response.body.message || "Server antwortet nicht.");
                    }
                }
            );
        },

        file_dl(row) {
            window.open(Vue.config.api_url + "/file/" + row.uid + "/" + encodeURIComponent(row.filename) + "?dl");
        },

        file_edit(row) {
            this.currentRow = row;
        },

        file_save(row) {
            let data = {
                meta_mediatype: this.currentRow.meta_mediatype,
                meta_maintype: this.mainType,
                is_main: !!this.currentRow.is_main,
            };
            let headers = {
                Authorization: "Bearer " + store(Vuex).getters.login.token,
                Accept: "application/json",
            };
            this.$http
                .post(Vue.config.api_url + "/meta/" + row.uid, data, { headers })
                .then((data) => {
                    this.currentRow = false;
                    this.load();
                    console.log(data);
                })
                .catch((err) => {
                    if (err.status == 401) {
                        this.$router.push("/login");
                    }
                });
        },
        file_delete(row) {
            let formData = new FormData();
            formData.append("deleted", 1);
            this.$http
                .delete(Vue.config.api_url + "/file/" + row.uid, {
                    method: "DELETE",
                    headers: {
                        Authorization: "Bearer " + store(Vuex).getters.login.token,
                        Accept: "application/json",
                        //"Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary" +  Math.random().toString().substr(2),
                    },
                    body: formData,
                })
                .then(
                    function (response) {
                        if (response.status == 401) {
                            this.$router.push("/login");
                        } else {
                            this.currentRow = false;
                            this.load();
                            console.log(response);
                        }
                    }.bind(this)
                );
        },
    },

    created() {
        //this.load();
        this.$watch(
            () => this.$route.params,
            (toParams, previousParams) => {
                console.log("route change: ", toParams, previousParams);
                if("productid" in toParams) {
                    this.entityid = toParams.productid;
                    this.mainType = "product";
                    store(Vuex).commit("mainType", this.mainType);
                } 
                if("collid" in toParams) {
                    this.entityid = toParams.collid;
                    this.mainType = "collection";
                    this.mediatypes = ["Kollektion"];
                    store(Vuex).commit("mainType", this.mainType);
                } 
                if(!this.entityid) {
                    var mainType = store(Vuex).getters.mainType;
                    if (mainType == 'product') {
                        this.$router.push('/images/' + window.productid);
                    }
                    if (mainType == 'collection') {
                        this.$router.push('/images-kollektion/' + window.productid);
                    }
                }
                this.form.meta_mediatype = this.mediatypes[0];
                this.load();
            },
            { immediate: true }
        );
        
        /*** IE 11 Fix to watch hash changes *******/
        if ('-ms-scroll-limit' in document.documentElement.style 
            && '-ms-ime-align' in document.documentElement.style) { // detect it's IE11
            window.addEventListener("hashchange", () => {
                var currentPath = window.location.hash.slice(1);
                if (this.$route.path !== currentPath) {
                    console.log("route change: ", currentPath, this.$route.path);
                    this.$router.push(currentPath)
                }
            }, false)
        }
    }
}
</script>

<style lang="scss" scoped>

#modal-upload.loading {
    position: fixed;
}

.modal-container {
    max-height: none;
    position: relative;
    .modal-header, .modal-body {
        padding: 8px;
    }
    .form-group {
        margin-top: 5px;
    }
}

.imgContainer {
    border: 1px solid #777;
    width: 150px;
    height: 150px;
    display: block;
    float: left;
    margin: 3px;
    background-color: #ccc;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 15px;
    text-align: center;
    background-size: 40px 40px;
    position: relative;
    &.isImage {
        background-size: contain;
    }
    &.is_main {
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border: 2px solid white;
            border-radius: 50%;
            right: 5px;
            top: 5px;
            background-color: #f08;
        }
    }
    &.loading {
        font-size: 20px;
    }
    &.addNew {
        width: 30px;
        padding: 0;
        background-color: #75a7d8;
        &::after {
            content: "+";
            height: 150px;
            width: 30px;
            font-size: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-family: courier;
            color: white;
        }
        &:hover {
            background-color: dodgerblue;
        }
    }
    & > .dropdown {
        position: absolute;
        top: 10px;
        left: 10px;
        .dropdown-toggle {
            display: none;
        }
    }
    & > .footer {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
        opacity: 0;
    }
    &:hover > .footer {
        opacity: 1;
    }
    &:hover > .dropdown .dropdown-toggle {
        display: block;
    }
}

</style>

<style lang="scss" >
    .imgContainer {
        & > img {
            align-self: center;
            max-width: 60%;
            max-height: 60%;
        }
        & > figure.iiz.image {
            display: flex;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            picture {
                align-self: center;
                img.iiz__img {
                    visibility: visible !important;
                    opacity: 1 !important;
                }
            }
            & > div {
                display: flex;
                align-self: center;
                margin: 0 auto;
            }
            & > div > picture {
                display: flex;
                align-self: center;
            }
        }
    }
</style>
